import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import _ from "lodash";

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Bio from "../components/bio"
import PostCard from "../components/postCard"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"

//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  //const tags = data.allMarkdownRemark.distinct
  let postCounter = 0

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Blog"
        keywords={[`blog`, `Denise Barbosa`, `rh`, `rh as a service`, `evolução`]}
      />
        
        <article className="post-content page-template no-image">
        <div className="post-content-body">
            <div className="tag-container">
              {
              /*
              tags.map( tag => {
                return(
                    <Link
                    key={tag}
                    style={{ textDecoration: "none" }}
                    to={`/tags/${_.kebabCase(tag)}`}
                    >
                    <div className="tag-item">#{tag}</div>
                    </Link>
                  )
                })
                */
                }
            </div>
            <div className="post-feed">
                {posts.map(({ node }) => {
                postCounter++
                return (
                    <PostCard
                    key={node.fields.slug}
                    count={postCounter}
                    node={node}
                    postClass={`post`}
                    />
                )
                })}
            </div>

        </div>
        </article>
       
      
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            description
            tags
            thumbnail
          }
        }
      }
      distinct(field: frontmatter___tags)
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
